// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getPrizesCollections, postPrizesFormData, getPrizesShops } from '@/services/prizes.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getPrizesCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[3].options = res.data.collections.prize_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop, item, creatData) {
      if (item.prop === 'prizeable_type') {
        creatData.prizeable_id = ''
        getPrizesShops(data).then(req => {
          this.formData[0].createDateItem[4].options = req.data.pois
        })
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        prize: obj
      }
      Object.keys(params.prize).forEach(res => {
        if (typeof params.prize[res] === 'boolean') {
          if (params.prize[res] === true) params.prize[res] = '1'
          if (params.prize[res] === false) params.prize[res] = '0'
        }
      })
      postPrizesFormData(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'prizesList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
